import {
	API_BASE_URL,
	FILE_BASE_URL,
	IMPORT_SAMPLE_PARENT_FILE,
	IMPORT_SAMPLE_STUDENTS_FILE,
	PUBLIC_LOGO_PATH,
	PUBLIC_NAV_COLLAPSED_LOGO_PATH,
	PUBLIC_FAVICON_LOGO_PATH,
	img,
	APP_VERSION,

	WS_HOST,
	WS_PORT,
	SOCKET_ENDPOINT_URL,
	PUSHER_CLUSTER,
	PUSHER_APP_KEY,

} from 'constants/ApiConstant';

export const dev = {
	WS_HOST: WS_HOST,
	SOCKET_ENDPOINT_URL: SOCKET_ENDPOINT_URL,
	WS_PORT: WS_PORT,
	PUSHER_CLUSTER: PUSHER_CLUSTER,
	PUSHER_APP_KEY: PUSHER_APP_KEY,

	API_ENDPOINT_URL: API_BASE_URL,
	FILE_ENDPOINT_URL: FILE_BASE_URL,
	IMPORT_SAMPLE_PARENT_FILE: IMPORT_SAMPLE_PARENT_FILE,
	IMPORT_SAMPLE_STUDENTS_FILE: IMPORT_SAMPLE_STUDENTS_FILE,
	PUBLIC_LOGO_PATH: PUBLIC_LOGO_PATH,
	PUBLIC_NAV_COLLAPSED_LOGO_PATH: PUBLIC_NAV_COLLAPSED_LOGO_PATH,
	PUBLIC_FAVICON_LOGO_PATH: PUBLIC_FAVICON_LOGO_PATH,
	img: img,
	APP_VERSION: APP_VERSION
};

const prod = {
	WS_HOST: WS_HOST,
	SOCKET_ENDPOINT_URL: SOCKET_ENDPOINT_URL,
	WS_PORT: WS_PORT,
	PUSHER_CLUSTER: PUSHER_CLUSTER,
	PUSHER_APP_KEY: PUSHER_APP_KEY,

	API_ENDPOINT_URL: API_BASE_URL,
	FILE_ENDPOINT_URL: FILE_BASE_URL,
	IMPORT_SAMPLE_PARENT_FILE: IMPORT_SAMPLE_PARENT_FILE,
	IMPORT_SAMPLE_STUDENTS_FILE: IMPORT_SAMPLE_STUDENTS_FILE,
	PUBLIC_LOGO_PATH: PUBLIC_LOGO_PATH,
	PUBLIC_NAV_COLLAPSED_LOGO_PATH: PUBLIC_NAV_COLLAPSED_LOGO_PATH,
	PUBLIC_FAVICON_LOGO_PATH: PUBLIC_FAVICON_LOGO_PATH,
	img: img,
	APP_VERSION: APP_VERSION
};

const test = {
	WS_HOST: WS_HOST,
	SOCKET_ENDPOINT_URL: SOCKET_ENDPOINT_URL,
	WS_PORT: WS_PORT,
	PUSHER_CLUSTER: PUSHER_CLUSTER,
	PUSHER_APP_KEY: PUSHER_APP_KEY,

	API_ENDPOINT_URL: API_BASE_URL,
	FILE_ENDPOINT_URL: FILE_BASE_URL,
	IMPORT_SAMPLE_PARENT_FILE: IMPORT_SAMPLE_PARENT_FILE,
	IMPORT_SAMPLE_STUDENTS_FILE: IMPORT_SAMPLE_STUDENTS_FILE,
	PUBLIC_LOGO_PATH: PUBLIC_LOGO_PATH,
	PUBLIC_NAV_COLLAPSED_LOGO_PATH: PUBLIC_NAV_COLLAPSED_LOGO_PATH,
	PUBLIC_FAVICON_LOGO_PATH: PUBLIC_FAVICON_LOGO_PATH,
	img: img,
	APP_VERSION: APP_VERSION
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
