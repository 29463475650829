import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  MANAGE_GUARDIAN,
  MANAGE_GUARDIAN_API_URL,
  UPDATE_GUARDIAN,
  REMOVE_PHOTO
} from 'constants/guardian/index';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
import { env } from "configs/EnvironmentConfig"

const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ManageGuardianErrors: {},
  permission: permission?.manage_guardian ?? [],
  ManageGuardianShowMessage: null,
  manageGuardianResult: [],
  ManageGuardianButtonSpinner: false,
  ManageGuardianTableLoading: true,
  ManageGuardianAddDrawer: false,
  ManageGuardianEditData: {},
  ManageGuardianButtonAndModelLabel: setLocale('guardians.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewManageGuardianData: [],
  ViewManageGuardianLoader: true,
  guardianPicture: null,
  DrawerStatus: 0,

};

export const createManageGuardian = createAsyncThunk("createManageGuardian", async (data) => {
  try {
    if (data instanceof FormData && data.get("id")) {
      return await CommonService.postData(data, UPDATE_GUARDIAN);
    } else {
      return await CommonService.postData(data, MANAGE_GUARDIAN_API_URL);
    }
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const getAllGuardians = createAsyncThunk("getAllGuardians", async (data) => {
  try {
    return await CommonService.getData(data, MANAGE_GUARDIAN_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
}
)

export const viewManageGuardian = createAsyncThunk("viewManageGuardian", async (id) => {
  try {
    return await CommonService.getSingleData(id, MANAGE_GUARDIAN_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const deleteManageGuardian = createAsyncThunk("deleteManageGuardian", async (data) => {
  try {
    return await CommonService.deleteData(data, MANAGE_GUARDIAN_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const removePhoto = createAsyncThunk("removePhoto", async (data) => {
  try {
    return await CommonService.postData(data, REMOVE_PHOTO);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})


export const manageManageGuardianSlice = createSlice({
  name: MANAGE_GUARDIAN,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ManageGuardianErrors = {};
    },
    ManageGuardianAddDrawerStatus: (state, action) => {
      // state.ManageGuardianErrors      = {};
      state.ManageGuardianAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ManageGuardianEditData = {};
      state.guardianPicture = null
      state.ManageGuardianButtonAndModelLabel = setLocale('guardians.add');
    },
    ManageGuardianEditWithDrawerStatus: (state, action) => {
      // state.ManageGuardianErrors = {};
      state.ManageGuardianAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ManageGuardianEditData = action.payload.data;
      state.guardianPicture = action?.payload?.photo_id ? env.FILE_ENDPOINT_URL + action.payload.photo_id : null
      state.ManageGuardianButtonAndModelLabel = setLocale('guardians.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    setGuardianPicture: (state, action) => {
      state.guardianPicture = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createManageGuardian.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ManageGuardianButtonSpinner = true;
      }).addCase(createManageGuardian.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ManageGuardianButtonSpinner = false;
        state.ManageGuardianErrors = {}
        state.ManageGuardianShowMessage = false;
      }).addCase(createManageGuardian.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ManageGuardianShowMessage = true;
        state.ManageGuardianButtonSpinner = false;
        state.ManageGuardianErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getAllGuardians.pending, (state, action) => {
        state.ManageGuardianButtonSpinner = true
        state.ManageGuardianTableLoading = true
      }).addCase(getAllGuardians.fulfilled, (state, action) => {
        state.ManageGuardianButtonSpinner = false
        state.ManageGuardianTableLoading = false
        state.manageGuardianResult = action.payload.data
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        }
      }).addCase(getAllGuardians.rejected, (state, action) => {
        state.ManageGuardianShowMessage = true
        state.ManageGuardianButtonSpinner = false
        state.ManageGuardianTableLoading = false
        state.ManageGuardianErrors = JSON.parse(action.error.message)
      })
      .addCase(viewManageGuardian.pending, (state, action) => {
        state.ViewManageGuardianLoader = true;
        state.ViewManageGuardianData = [];
      }).addCase(viewManageGuardian.fulfilled, (state, action) => {
        state.ViewManageGuardianLoader = false;
        state.ViewManageGuardianData = action.payload;
      }).addCase(viewManageGuardian.rejected, (state, action) => {
        state.ViewManageGuardianLoader = false;
        state.ViewManageGuardianData = [];
      })
      .addCase(deleteManageGuardian.pending, (state, action) => {
      }).addCase(deleteManageGuardian.fulfilled, (state, action) => {
      }).addCase(deleteManageGuardian.rejected, (state, action) => {
      })
      .addCase(removePhoto.pending, (state, action) => {
      }).addCase(removePhoto.fulfilled, (state, action) => {
        state.guardianPicture = null
      }).addCase(removePhoto.rejected, (state, action) => {
      });
  },
});

export const {
  onCloseError,
  ManageGuardianAddDrawerStatus,
  ManageGuardianEditWithDrawerStatus,
  updateSortFilters,
  setColumnSearch,
  setGuardianPicture
} = manageManageGuardianSlice.actions;

export default manageManageGuardianSlice.reducer;
