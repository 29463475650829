import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ALL_MANAGE_TEACHERS_API_URL, MANAGE_TEACHERS, MANAGE_TEACHERS_API_URL } from 'constants/AppConstants';
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
import { env } from "configs/EnvironmentConfig"

const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
export const initialState = {
  ManageTeacherErrors: {},
  ManageTeacherShowMessage: null,
  ManageTeacherResult: [],
  ManageTeacherButtonSpinner: false,
  ManageTeacherTableLoading: true,
  ManageTeacherAddDrawer: false,
  ManageTeacherEditData: [],
  ManageTeacherButtonAndModelLabel: setLocale('teachers.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewManageTeacherData: [],
  ViewManageTeacherLoader: true,
  teacherProfilePicture: null,
};

export const createManageTeacher = createAsyncThunk(
  "createManageTeacher",
  async (data) => {
    try {
      const response = await CommonService.postData(data, MANAGE_TEACHERS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
export const updateManageTeacher = createAsyncThunk(
  "updateManageTeacher",
  async (data) => {
    try {
      const response = await CommonService.postData(data, "manage-teachers/update");
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getManageTeacher = createAsyncThunk(
  "getManageTeacher",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_MANAGE_TEACHERS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
export const viewManageTeacher = createAsyncThunk("viewManageTeacher", async (id) => {
  try {
    return await CommonService.getSingleData(id, MANAGE_TEACHERS_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const deleteManageTeacher = createAsyncThunk(
  "deleteManageTeacher",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, MANAGE_TEACHERS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageManageTeacherSlice = createSlice({
  name: MANAGE_TEACHERS,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ManageTeacherErrors = {};
    },
    ManageTeacherAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.ManageTeacherButtonSpinner = false;
      }
      // state.ManageTeacherErrors = {};
      state.ManageTeacherAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ManageTeacherEditData = [];
      state.ManageTeacherButtonAndModelLabel = setLocale('teachers.add');
    },
    ManageTeacherEditWithDrawerStatus: (state, action) => {
      // state.ManageTeacherErrors = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.ManageTeacherAddDrawer = true;
      state.ManageTeacherEditData = action.payload.data;
      state.teacherProfilePicture = action.payload.data?.profile_picture ? env.FILE_ENDPOINT_URL + action.payload.data?.profile_picture : env.img
      state.ManageTeacherButtonAndModelLabel = setLocale('teachers.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    setTeacherProfilePicture: (state, action) => {
      state.teacherProfilePicture = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createManageTeacher.pending, (state, action) => {
        state.ManageTeacherButtonSpinner = true;
        state.DrawerStatus = 0
      }).addCase(createManageTeacher.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ManageTeacherButtonSpinner = false;
        // state.teacherProfilePicture = action.payload?.data?.profile_picture ? env.FILE_ENDPOINT_URL + action.payload?.data?.profile_picture : null
        state.ManageTeacherErrors = {}
      }).addCase(createManageTeacher.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ManageTeacherShowMessage = true;
        state.ManageTeacherButtonSpinner = false;
        state.ManageTeacherErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(updateManageTeacher.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ManageTeacherButtonSpinner = true;
      }).addCase(updateManageTeacher.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ManageTeacherButtonSpinner = false;
        state.ManageTeacherErrors = {}
        state.teacherProfilePicture = action.payload?.data?.profile_picture ? env.FILE_ENDPOINT_URL + action.payload?.data?.profile_picture : null
      }).addCase(updateManageTeacher.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ManageTeacherShowMessage = true;
        state.ManageTeacherButtonSpinner = false;
        state.ManageTeacherErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getManageTeacher.pending, (state, action) => {
        state.ManageTeacherButtonSpinner = true;
        state.ManageTeacherTableLoading = true;
      }).addCase(getManageTeacher.fulfilled, (state, action) => {
        state.ManageTeacherButtonSpinner = false;
        state.ManageTeacherTableLoading = false;
        state.ManageTeacherResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getManageTeacher.rejected, (state, action) => {
        // state.ManageTeacherErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.ManageTeacherShowMessage = true; // Set the showMessage flag to display the errors
        state.ManageTeacherButtonSpinner = false;
        state.ManageTeacherTableLoading = false;
      })
      .addCase(viewManageTeacher.pending, (state, action) => {
        state.ViewManageTeacherLoader = true;
        state.ViewManageTeacherData = [];
      }).addCase(viewManageTeacher.fulfilled, (state, action) => {
        state.ViewManageTeacherLoader = false;
        state.ViewManageTeacherData = action.payload;
      }).addCase(viewManageTeacher.rejected, (state, action) => {
        state.ViewManageTeacherLoader = false;
        state.ViewManageTeacherData = [];
      })
      .addCase(deleteManageTeacher.pending, (state, action) => {

      }).addCase(deleteManageTeacher.fulfilled, (state, action) => {

      }).addCase(deleteManageTeacher.rejected, (state, action) => {

      });
  },
});

export const { onCloseError, setColumnSearch, ManageTeacherAddDrawerStatus, ManageTeacherEditWithDrawerStatus, updateSortFilters, setTeacherProfilePicture } = manageManageTeacherSlice.actions;

export default manageManageTeacherSlice.reducer;
